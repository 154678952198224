<template>
<Card>
    <template #content>
		<div class="p-m-500">
			<p>Seleccionar el grupo de inmuebles a los que se les emitirá cargos, usando los siguientes criterios:</p>
            <Divider />
			<div class="p-fluid p-formgrid p-grid">
				<div class="p-fluid p-field p-ml-3">
					<label for="nivel1" class="p-text-bold">{{ complejo.nivel1 }}</label>
					<Dropdown v-model="identificador1" :options="lkpNivel1" optionLabel="identificador" optionValue="identificador" @change="actualizarIdentificadores($event, 2)"/>
				</div>	
			</div>	
			<div class="p-fluid p-formgrid p-grid">		
				<div class="p-fluid p-field p-ml-3" :style="complejo.nivel2==''?'display:none':''">
						<label for="nivel2" class="p-text-bold">{{ complejo.nivel2 }}</label>
						<Dropdown v-model="identificador2" :options="lkpNivel2" optionLabel="identificador" optionValue="identificador"/>
				</div>
			</div>	
			<div class="p-fluid p-formgrid p-grid">			
				<div class="p-field p-ml-3" :style="complejo.nivel3==''?'display:none':''">
						<label for="nivel3" class="p-text-bold">{{ complejo.nivel3 }}</label>
						<Dropdown v-model="identificador3" :options="lkpNivel3" optionLabel="identificador" optionValue="identificador"/>
				</div>
			</div>
			<div class="p-fluid p-formgrid p-grid">
				<div class="p-field p-ml-3" :style="complejo.nivel4==''?'display:none':''">
						<label for="nivel4" class="p-text-bold">{{ complejo.nivel4 }}</label>
						<Dropdown v-model="identificador4" :options="lkpNivel4" optionLabel="identificador" optionValue="identificador"/>		
				</div>
			</div>
			<div class="p-fluid p-formgrid p-grid">
				<div class="p-field p-ml-3" :style="complejo.nivel5==''?'display:none':''">
						<label for="nivel5" class="p-text-bold">{{ complejo.nivel5 }}</label>
						<Dropdown v-model="identificador5" :options="lkpNivel5" optionLabel="identificador" optionValue="identificador"/>
				</div>
			</div>
			<Divider />
			<div class="p-fluid p-formgrid p-grid">		
				<div class="p-field p-ml-3">
					<label for="categoria" class="p-text-bold">Categoría</label>
					<Dropdown id="categoria" v-model="id_categoria" :options="lkpCategorias" optionLabel="categoria" optionValue="id_cta_habiente_categoria" placeholder="Categoría..." />						
				</div>	
			</div>
			<Divider />
			<div class="p-fluid p-formgrid p-grid">
				<div class="p-field p-ml-3">
					<label for="ocupado" class="p-text-bold">Ocupación</label>
					<Dropdown id="ocupado" v-model="ocupacion" :options="lkpOcupados" optionLabel="titulo" optionValue="id" placeholder="Ocupación..." />						
				</div>	   	
			</div>
		</div>
    </template>
    <template #footer>
		<div class="p-d-flex p-jc-end">
		<Button label="Siguiente" icon="pi pi-angle-right" @click="nextPage()"  icon-pos="right"/>	
		</div>
    </template>
</Card>				

	<Dialog v-model:visible="procesandoDlg" :showHeader="true" :modal="true" :closable="false">			
		<div class="flex align-content-center flex-wrap card-container" :style="{width: '500px', height: '250'}">
			<div class="p-d-flex p-ai-center p-jc-center">
				<i class="pi pi-send p-m-2" style="font-size: 2rem"></i>
			</div>
			<div class="flex align-items-center justify-content-center mt-5">				
			<ProgressBar :value="secuencia">										
				<div class="font-light">
				Enviando {{secuencia}} de {{selectedMovs.length}}
				</div>
			</ProgressBar>
			</div>
		</div>
	</Dialog>
</template>
<script>
import MdsService from '../../service/mds.service';
import { CURRENT_APP_MODE } from '../../service/constants';
import Divider from 'primevue/divider';

export default {
    name: 'MdsEmisionInmuebles',
	props: {
		formData: {
			type: Object,
			Default() {
				return {
					id: '',
					id_mov_plantilla: '0',
					id_cta_habiente_info: '',
					id_categoria: '',
					tasa_cambio: null,
					fecha_emision: null,
					cambiar_fecha_pago: false,
					fecha_pago: null,
					Param1: '',
					Param2: '',
					Param3: '',
					Param4: '',
					Param5: '',
					Param6: '0',							
				}
			}
		}
	},
	components: {
		Divider
	},
	data() {        
        return {
            appMode: CURRENT_APP_MODE,
			id: this.formData.id,
			id_mov_plantilla: this.formData.id_mov_plantilla,
			id_cta_habiente_info: this.formData.id_cta_habiente_info,
			id_categoria: this.formData.id_categoria,
			tasa_cambio: this.formData.tasa_cambio,
			fecha_emision: this.formData.fecha_emision,
			cambiar_fecha_pago: this.formData.cambiar_fecha_pago,
			fecha_pago: this.formData.fecha_pago,
			secuencia: 1,
			procesandoDlg: false,
			Param1: this.formData.Param1,
			Param2: this.formData.Param2,
			Param3: this.formData.Param3,
			Param4: this.formData.Param4,
			Param5: this.formData.Param5,
			Param6: this.formData.Param6,					
			lkpCategorias: [
				{id_cta_habiente_categoria: '', categoria: '(Todas)'}				
			],
			lkpOcupados: [{id: '0', titulo: '(Todos)'}, {id: 'S', titulo: 'Ocupados'}, {id: 'N', titulo: 'Desocupados'}],
			complejo: this.$store.state.auth.currentAppCtx.complejo,
			lkpNivel1: [{identificador: '(Todos)'}],
			lkpNivel2: [{identificador: '(Todos)'}],
			lkpNivel3: [{identificador: '(Todos)'}],
			lkpNivel4: [{identificador: '(Todos)'}],
			lkpNivel5: [{identificador: '(Todos)'}],
			identificador1: this.formData.Param1==''?'(Todos)':this.formData.Param1,			
			identificador2: this.formData.Param2==''?'(Todos)':this.formData.Param2,
			identificador3: this.formData.Param3==''?'(Todos)':this.formData.Param3,
			identificador4: this.formData.Param4==''?'(Todos)':this.formData.Param4,
			identificador5: this.formData.Param5==''?'(Todos)':this.formData.Param5,
			ocupacion: this.formData.Param6,				
        }
    },
	mounted() {
		Array.prototype.push.apply(this.lkpCategorias, this.$store.state.auth.currentAppCtx.ctahabiente_categorias);
		this.refrescarNivelesInferiores(1, '');
	},    
    methods: {
		async cargarIdentificadores(nivel, valor)
		{
			let data = await MdsService.getIdentificadoresXNivel(this.$store.state.auth.currentAppCtx, nivel, valor);	
			return data;			
		},
		async refrescarNivelesInferiores(nivel, valorSuperior){
			switch(nivel) {
			case 1: {
					this.lkpNivel1 = [{identificador: '(Todos)'}];				
					let lista = await this.cargarIdentificadores(1, '');				
					Array.prototype.push.apply(this.lkpNivel1, lista);																						
					this.refrescarNivelesInferiores(nivel+1, this.identificador1);										
				}
				break;				
			case 2:
				if (this.complejo.nivel2 != '') {
					this.lkpNivel2 = [{identificador: '(Todos)'}];
					if(valorSuperior!='(Todos)'){
						let lista = await this.cargarIdentificadores(2, valorSuperior);
						Array.prototype.push.apply(this.lkpNivel2, lista);								
					}
					this.refrescarNivelesInferiores(nivel+1, this.identificador2);
				}
				break;
			case 3:
				if (this.complejo.nivel3 != '') {
					this.lkpNivel3 = [{identificador: '(Todos)'}];
					if(valorSuperior!='(Todos)'){
						let lista = await this.cargarIdentificadores(3, valorSuperior);
						Array.prototype.push.apply(this.lkpNivel3, lista);								
					}
					this.refrescarNivelesInferiores(nivel+1, this.identificador3);
				}
				break;
			case 4:
				if (this.complejo.nivel4 != '') {
					this.lkpNivel4 = [{identificador: '(Todos)'}];
					if(valorSuperior!='(Todos)'){
						let lista = await this.cargarIdentificadores(4, valorSuperior);
						Array.prototype.push.apply(this.lkpNivel4, lista);								
					}
					this.refrescarNivelesInferiores(nivel+1, this.identificador4);
				}
				break;
			case 5:
				if (this.complejo.nivel5 != '') {
					this.lkpNivel5 = [{identificador: '(Todos)'}];
					if(valorSuperior!='(Todos)'){
						let lista = await this.cargarIdentificadores(5, valorSuperior);
						Array.prototype.push.apply(this.lkpNivel5, lista);								
					}
				}
				break;	
			default:
				// code block
			}			
			// lkpNivel1: [{identificador: '(Todos)'}],
			
		},		
		actualizarIdentificadores(e, nivel) {
			this.refrescarNivelesInferiores(nivel, e.value);
		},
        nextPage(){
			let formData = {
				id: this.id,
				id_mov_plantilla: this.id_mov_plantilla,
				id_cta_habiente_info: this.id_cta_habiente_info,
				id_categoria: this.id_categoria,
				tasa_cambio: this.tasa_cambio,
				fecha_emision: this.fecha_emision,
				cambiar_fecha_pago: this.cambiar_fecha_pago,
				fecha_pago: this.fecha_pago,
				Param1: this.identificador1 == '(Todos)'? '': this.identificador1,
				Param2: this.identificador2 == '(Todos)'? '': this.identificador2,
				Param3: this.identificador3 == '(Todos)'? '': this.identificador3,
				Param4: this.identificador4 == '(Todos)'? '': this.identificador4,							
				Param5: this.identificador5 == '(Todos)'? '': this.identificador5,
				Param6: this.ocupacion
			};

            this.$emit('nextPage', {condiciones: formData, pageIndex: 0});
        }
    }
}
</script>

<style scoped lang="scss">
::v-deep(b) {
    display: block;
}

::v-deep(.p-card-body) {
    padding: 2rem;
}
</style>